import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { RouteSlug } from '../models/enums/route-slug.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangePasswordComponent } from '../pages/main/_components/change-password/change-password.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private readonly _injector: Injector) {}

  public handleError(error: Error | HttpErrorResponse): void {
    if (!error) {
      return;
    }
    const _toastrService = this._injector.get(ToastrService);
    const _store = this._injector.get(Store);

    if (error instanceof HttpErrorResponse) {
      if (error.status === 400) {
        type errorType = { [key: string]: { errors: string[]; children: errorType } };
        const children: errorType = error?.error?.errors?.children;
        const showErrorNotifications = (children: errorType) => {
          Object.values(children).forEach((value) => {
            if (Array.isArray(value?.errors)) {
              value.errors.forEach((message) => {
                _toastrService.error(message);
              });
            }
            if (value.children) {
              showErrorNotifications(value.children);
            }
          });
        };
        if (children) {
          showErrorNotifications(children);
        }
      } else if (error.status === 404) {
        _toastrService.error('Zasób nieodnaleziony', 'Błąd');
      } else if (error.status === 403) {
        if (error?.error?.message_code === 400) {
          _toastrService.error('Dostęp zabroniony, wymagana zmiana hasła.', 'Błąd');
          this._createModalChangePasswordWindow().catch();
        } else {
          _toastrService.error('Dostęp zabroniony.', 'Błąd');
          _store.dispatch(new Navigate([`/${RouteSlug.AUTH}`, RouteSlug.LOGIN]));
        }
      } else if (error.status === 500) {
        _toastrService.error('Coś poszło nie tak!!!', 'Błąd');
      }
    }

    throw error;
  }

  private _createModalChangePasswordWindow(): Promise<unknown> {
    const _ngbModal = this._injector.get(NgbModal);
    const ngbModalRef = _ngbModal.open(ChangePasswordComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static',
      container: 'nb-layout',
    });
    ngbModalRef.componentInstance.changePasswordRequired = true;
    return ngbModalRef.result;
  }
}
