import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { IPermission } from './models/permissions/permission';
import { PermissionEnum } from './models/permissions/permission.enum';
import { IUser } from './models/user';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { DictionariesService } from './services/dictionaries.service';
import { IUserDropDown } from '@h2h/data';
import { RouteSlug } from './models/enums/route-slug.enum';
import { RoleEnum } from './models/enums/role.enum';
import { Theme } from './models/enums/theme.enum';

export class AppStateGetUsers {
  public static readonly type = '[AppState] get users';
}

export class AppStateSetJwtToken {
  public static readonly type = '[AppState] set jwt token';

  constructor(public readonly payload: { jwtToken: string | null }) {}
}

export class AppStateGetRoles {
  public static readonly type = '[AppState] get user roles';
}

export class AppStateGetPermissions {
  public static readonly type = '[AppState] get user permissions';
}

export class AppStateLogout {
  public static readonly type = '[AppState] logout';
}

export class AppStateSetUserData {
  public static readonly type = '[AppState] set user data';

  constructor(public readonly payload: { user: IUser }) {}
}

export class AppStateSetFetchUserDataAgain {
  public static readonly type = '[AppState] fetch user data again';
}

export class AppStateToggleNavigationMenu {
  public static readonly type = '[AppState] toggle navigation menu';
}

export class AppStateSetTheme {
  public static readonly type = '[AppState] set theme';

  constructor(public readonly payload: { theme: Theme }) {}
}

export interface AppStateModel {
  loading: boolean;
  token: string | null;
  user: IUser | null;
  isNavigationMenuExpanded: boolean;
  theme: Theme;
  permissionsObtained: string[];
  roles: IPermission[] | null;
  permissions: IPermission[] | null;
  users: IUserDropDown[] | null;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: false,
    token: '',
    user: null,
    isNavigationMenuExpanded: true,
    theme: Theme.DEFAULT,
    permissionsObtained: [],
    roles: null,
    permissions: null,
    users: null,
  },
})
@Injectable()
export class AppState {
  constructor(
    private readonly _userService: UserService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _dictionariesService: DictionariesService,
    private readonly _ngxPermissionsService: NgxPermissionsService,
  ) {}

  @Selector()
  public static loading({ loading }: AppStateModel): boolean {
    return loading;
  }

  @Selector()
  public static token({ token }: AppStateModel): string | null {
    return token;
  }

  @Selector()
  public static user({ user }: AppStateModel): IUser | null {
    return user;
  }

  @Selector()
  public static roles({ roles }: AppStateModel): IPermission[] | null {
    return roles;
  }

  @Selector()
  public static permissions({ permissions }: AppStateModel): IPermission[] | null {
    return permissions;
  }

  @Selector()
  public static permissionsObtained({ permissionsObtained }: AppStateModel): string[] {
    return permissionsObtained;
  }

  @Selector()
  public static users({ users }: AppStateModel): IUserDropDown[] | null {
    return users;
  }

  @Selector()
  public static theme({ theme }: AppStateModel): Theme {
    return theme;
  }

  @Selector()
  public static isNavigationMenuExpanded({ isNavigationMenuExpanded }: AppStateModel): boolean {
    return isNavigationMenuExpanded;
  }

  @Action(AppStateSetJwtToken)
  public setJwtToken(
    { patchState }: StateContext<AppStateModel>,
    { payload: { jwtToken } }: AppStateSetJwtToken,
  ): Observable<AppStateModel> {
    return of(patchState({ token: jwtToken }));
  }

  @Action(AppStateSetUserData)
  public setUserData(
    { patchState }: StateContext<AppStateModel>,
    { payload: { user } }: AppStateSetUserData,
  ): Observable<AppStateModel> {
    this._loadPermissionsStrings(user, patchState);
    return of(patchState({ user }));
  }

  @Action(AppStateLogout)
  public logout({ patchState, dispatch }: StateContext<AppStateModel>): Observable<void> {
    return this._authenticationService.logout().pipe(
      concatMap(() => dispatch(new Navigate([`/${RouteSlug.AUTH}`, RouteSlug.LOGIN]))),
      finalize(() => {
        patchState({ user: null, token: null });
      }),
    );
  }

  @Action(AppStateGetRoles)
  public getRoles({ patchState, getState }: StateContext<AppStateModel>): Observable<IPermission[]> {
    const { roles } = getState();
    if (roles) {
      return of(roles);
    }
    patchState({ loading: true });
    return this._dictionariesService.getAllRoles().pipe(
      tap((_roles) => {
        patchState({ roles: _roles, loading: false });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateGetPermissions)
  public getPermissions({ patchState, getState }: StateContext<AppStateModel>): Observable<IPermission[]> {
    const { permissions } = getState();
    if (permissions) {
      return of(permissions);
    }
    patchState({ loading: true });
    return this._dictionariesService.getAllPermissions().pipe(
      tap((_permissions) => {
        patchState({ permissions: _permissions });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateGetUsers)
  public getUsers({ getState, patchState }: StateContext<AppStateModel>): Observable<IUserDropDown[]> {
    const { users } = getState();
    if (users) {
      return of(users);
    }
    patchState({ loading: true });
    return this._dictionariesService.getUsers().pipe(
      tap((_users) => {
        patchState({ users: _users, loading: false });
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateSetFetchUserDataAgain)
  public fetchUserDataAgain({ patchState, dispatch }: StateContext<AppStateModel>): Observable<unknown> {
    patchState({ loading: true });
    return this._userService.getMe().pipe(
      tap((user) => {
        dispatch(new AppStateSetUserData({ user }));
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }

  @Action(AppStateToggleNavigationMenu)
  public toggleNavigationMenu({ getState, patchState }: StateContext<AppStateModel>): void {
    const { isNavigationMenuExpanded } = getState();
    patchState({ isNavigationMenuExpanded: !isNavigationMenuExpanded });
  }

  @Action(AppStateSetTheme)
  public setTheme({ patchState }: StateContext<AppStateModel>, { payload: { theme } }: AppStateSetTheme): void {
    patchState({ theme });
  }

  private _loadPermissionsStrings(user: IUser, patchState: (val: Partial<AppStateModel>) => AppStateModel): void {
    this._ngxPermissionsService.flushPermissions();
    if (user?.role === RoleEnum.ROLE_SUPER_ADMIN) {
      const permissionsEnumString = Object.keys(PermissionEnum);
      patchState({
        permissionsObtained: [RoleEnum.ROLE_SUPER_ADMIN, ...permissionsEnumString],
      });
      this._ngxPermissionsService.loadPermissions([RoleEnum.ROLE_SUPER_ADMIN, ...permissionsEnumString]);
    } else if (user?.permissions) {
      const { permissions } = user;
      const permissionsStrings: string[] = permissions.map(({ role }) => role);
      patchState({ permissionsObtained: [user.role, ...permissionsStrings] });
      this._ngxPermissionsService.loadPermissions([user.role, ...permissionsStrings]);
    } else {
      this._ngxPermissionsService.loadPermissions([]);
    }
  }
}
