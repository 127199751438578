import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly _httpClient: HttpClient) {}

  /**
   * Get logged user details
   */
  public getMe(): Observable<IUser> {
    return this._httpClient.get<IUser>(`${environment.api}/user/me`);
  }
}
