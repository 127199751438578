export enum RouteSlug {
  AUTH = 'auth',
  LOGIN = 'login',
  FORGOT = 'forgot',
  SET_NEW_PASSWORD = 'change-forgotten-password',
  USERS = 'users',
  PERMISSIONS = 'permissions',
  ROLES = 'roles',
  SYS_LOGS = 'sys-logs',
  EMAILS = 'emails',
  SMS = 'sms',
  CRON_JOBS = 'cron-jobs',
  OAUTH_CLIENTS = 'oauth-clients',
  CLIENTS = 'clients',

  FORBIDDEN = '403',
  NOT_FOUND = '404',
  EDIT = 'edit',
  CREATE = 'create',
  LIST = 'list',
}
