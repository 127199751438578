import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private readonly _httpClient: HttpClient) {}

  /**
   * Logout user. Destroy session.
   */
  public logout(): Observable<void> {
    return this._httpClient.get<void>(`${environment.api}/logout`);
  }
}
