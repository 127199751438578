import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { AuthGuard } from './guards/auth.guard';

@Component({
  selector: 'ams-root',
  template: `
    <div class="central-loader" *ngIf="loader$ | async">
      <div><i class="fa fa-spin fa-spinner"></i>&nbsp; Loading...</div>
    </div>
    <router-outlet></router-outlet>
  `,
  styles: [
    `
      .central-loader {
        position: fixed;
        display: flex;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.2);
        z-index: 1050;
      }

      .central-loader > div {
        height: 42px;
        line-height: 40px;
        width: 200px;
        text-align: center;
        border: 2px #faf2cc solid;
        color: #8a6d3b;
        background-color: #fcf8e3;
        margin: auto;
      }
    `,
  ],
})
export class AppComponent {
  public readonly loader$ = this._authGuardService.loader$;

  constructor(private readonly _authGuardService: AuthGuard, private readonly _nbIconLibraries: NbIconLibraries) {
    this._nbIconLibraries.registerFontPack('fa', {
      iconClassPrefix: 'fa',
      packClass: 'fas',
    });
    this._nbIconLibraries.registerFontPack('fab', {
      iconClassPrefix: 'fa',
      packClass: 'fab',
    });
  }
}
