import { FileUploaderOptions } from 'ng2-file-upload';

export class AppSettings {
  // public static readonly DATE_FORMAT = 'yyyy-MM-dd';
  public static readonly DATE_FORMAT = 'dd MMMM yyyy';
  public static readonly SAVE_DATE_FORMAT = 'yyyy-MM-dd';
  public static readonly DATE_TIME_FORMAT = 'dd MMMM yyyy, HH:mm';
  public static readonly FILE_UPLOADER_OPTIONS: FileUploaderOptions = {
    url: '',
    disableMultipart: false,
    autoUpload: false,
    queueLimit: 1,
    // 2mb
    maxFileSize: 2097152,
  };
}
