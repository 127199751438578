import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbAuthModule } from '@nebular/auth';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbOverlayModule,
  NbSidebarModule,
  NbThemeModule,
  NbTimepickerModule,
  NbWindowModule,
} from '@nebular/theme';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { Navigate, NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { pl } from 'date-fns/locale';
import { provideNgxMask } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { ToastrModule } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { UserService } from './services/user.service';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routing';
import { AppState, AppStateSetJwtToken, AppStateSetUserData } from './app.state';
import { Theme } from './models/enums/theme.enum';
import { RouteSlug } from './models/enums/route-slug.enum';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AppSettings } from './app.settings';
import { ErrorHandlerService } from './services/error-handler.service';

registerLocaleData(localePl);

const THEME_KEY = 'app.theme';
const LOCAL_STORAGE_STATE_VALUES = ['app.token', 'app.isNavigationMenuExpanded', THEME_KEY];

const NGXS_MODULES = [
  NgxsModule.forRoot([AppState], {
    developmentMode: !environment.production,
  }),
  NgxsRouterPluginModule.forRoot(),
  NgxsFormPluginModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    logger: console,
    collapsed: false,
    disabled: environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    key: [...LOCAL_STORAGE_STATE_VALUES],
  }),
];

const currentTheme: Theme = localStorage.getItem(THEME_KEY)?.replace(/"/gi, '') as Theme;

const NEBULAR_MODULES = [
  NbThemeModule.forRoot({ name: currentTheme || Theme.DEFAULT }),
  NbMenuModule.forRoot(),
  NbSidebarModule.forRoot(),
  NbAuthModule.forRoot({}),
  NbWindowModule.forRoot(),
  NbDialogModule.forRoot(),
  NbEvaIconsModule,
  NbOverlayModule.forRoot(),
  NbDatepickerModule.forRoot(),
  NbTimepickerModule.forRoot(),
  NbDateFnsDateModule.forRoot({
    format: AppSettings.DATE_FORMAT,
    parseOptions: {
      useAdditionalWeekYearTokens: true,
      useAdditionalDayOfYearTokens: true,
      locale: pl,
    },
    formatOptions: {
      useAdditionalWeekYearTokens: true,
      useAdditionalDayOfYearTokens: true,
      locale: pl,
    },
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-left',
    }),
    HttpClientModule,
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: !environment.production,
      preloadingStrategy: QuicklinkStrategy,
    }),
    NgxPermissionsModule.forRoot(),
    QuicklinkModule,
    ...NGXS_MODULES,
    ...NEBULAR_MODULES,
    RecaptchaV3Module,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pl-PL',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
    { provide: RECAPTCHA_LANGUAGE, useValue: 'pl' },
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService, store: Store) => async () => {
        // hack for randomly not loading css
        await new Promise((resolve) => setTimeout(resolve, 500));
        const jwtHelperService = new JwtHelperService();
        const token = store.selectSnapshot(AppState.token);
        if (!token || jwtHelperService.isTokenExpired(token)) {
          return () => new Promise((resolve) => resolve);
        }
        return firstValueFrom(userService.getMe())
          .then((user) => {
            store.dispatch(new AppStateSetUserData({ user }));
          })
          .catch((reason) => {
            if (reason?.status === 403) {
              store.dispatch([
                new AppStateSetJwtToken({ jwtToken: null }),
                new Navigate([`/${RouteSlug.AUTH}`, RouteSlug.LOGIN]),
              ]);
            }
          });
      },
      deps: [UserService, Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
