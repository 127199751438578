import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordService {
  constructor(private readonly _httpClient: HttpClient) {}

  /**
   * Change password for logged user and clear require password update field.
   */
  public changePassword(currentPassword: string, newPassword: string, repeatPassword: string): Observable<void> {
    return this._httpClient.post<void>(`${environment.api}/user/change-password`, {
      currentPassword,
      newPassword,
      repeatPassword,
    });
  }
}
