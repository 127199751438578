import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { INgxsFormModel, setFormErrors } from '@h2h/data';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ChangePasswordService } from './change-password.service';

export class ChangePasswordStateChangePassword {
  public static readonly type = '[ChangePasswordState] change password';

  constructor(public readonly payload: { formGroup: UntypedFormGroup }) {}
}

export interface ChangePasswordModel {
  form: INgxsFormModel<{
    currentPassword: string | null;
    newPassword: string | null;
    repeatPassword: string | null;
  }> | null;
  loading: boolean;
  oldPasswordNotCorrect: string | null;
}

@State<ChangePasswordModel>({
  name: 'changePassword',
  defaults: {
    form: null,
    loading: false,
    oldPasswordNotCorrect: null,
  },
})
@Injectable()
export class ChangePasswordState {
  constructor(private readonly _changePasswordService: ChangePasswordService) {}

  @Selector()
  public static loading({ loading }: ChangePasswordModel): boolean {
    return loading;
  }

  @Selector()
  public static oldPasswordNotCorrect({ oldPasswordNotCorrect }: ChangePasswordModel): string | null {
    return oldPasswordNotCorrect;
  }

  @Action(ChangePasswordStateChangePassword, { cancelUncompleted: true })
  public changePassword(
    { patchState }: StateContext<ChangePasswordModel>,
    { payload: { formGroup } }: ChangePasswordStateChangePassword,
  ): Observable<void> {
    const { currentPassword, newPassword, repeatPassword } = formGroup.value;
    patchState({ loading: true, oldPasswordNotCorrect: null });
    return this._changePasswordService.changePassword(currentPassword, newPassword, repeatPassword).pipe(
      catchError((err) => {
        if (err?.error?.code === 400) {
          setFormErrors(err?.error?.errors?.children, formGroup);
          formGroup.updateValueAndValidity();
        }
        return throwError(() => err);
      }),
      finalize(() => {
        patchState({ loading: false });
      }),
    );
  }
}
