import { animate, style, transition, trigger } from '@angular/animations';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type errorType =
  | 'isTaken'
  | 'email'
  | 'required'
  | 'invalidDate'
  | 'invalidDateRange'
  | 'maxlength'
  | 'minlength'
  | 'min'
  | 'max'
  | 'mask'
  | 'matchOther'
  | 'nip'
  | 'pattern'
  | 'hasUpper'
  | 'hasSpecial'
  | 'hasNumber'
  | 'hasLower'
  | 'backend';

@Component({
  selector: 'ams-control-messages',
  template: `
    <p class="error-message" @enterAnimation *ngIf="touched && (errorMessage$ | async)">
      {{ errorMessage$ | async }}
    </p>
  `,
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('150ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('150ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  imports: [CommonModule],
  standalone: true,
})
@UntilDestroy()
export class ControlMessagesComponent implements OnInit, DoCheck {
  @Input()
  public control?: AbstractControl;
  public touched = false;
  private _errorMsg = new BehaviorSubject<string>('');
  public errorMessage$ = this._errorMsg.asObservable();

  private static _getValidatorErrorMessage(validatorName: errorType, validatorValue?: any): string {
    const passwordsMessage =
      'Hasło powinno zawierać co najmniej 8 znaków, w tym małą i dużą literę, znak specjalny i cyfrę';
    const config = {
      isTaken: 'To pole musi być unikalne.',
      email: 'Niepoprawny format email.',
      required: 'To pole jest wymagane.',
      invalidDate: 'Niepoprawny format daty',
      invalidDateRange: 'Niepoprawny zakres daty',
      maxlength: `Maksymalna długość pola to ${validatorValue['requiredLength']}`,
      minlength: `Minimalna długość pola to ${validatorValue['requiredLength']}`,
      min: `Minimalna wartość pola to ${validatorValue['min']}`,
      max: `Maksymalna wartość pola to ${validatorValue['max']}`,
      mask: `Wymagany jest format ${validatorValue['requiredMask']}`,
      matchOther: `Podane hasła muszą być identyczne`,
      nip: 'Podaj poprawny numer NIP.',
      pattern: `Niepoprawny wzór, wymagany "${validatorValue['requiredPattern']
        ?.replaceAll('\\', '')
        .replaceAll('?', '')
        .replaceAll('^', '')
        .replaceAll('.com//', '.com/')
        .replaceAll('/https', 'https')}"`,
      hasUpper: passwordsMessage,
      hasSpecial: passwordsMessage,
      hasNumber: passwordsMessage,
      hasLower: passwordsMessage,
      backend: validatorValue?.errors || validatorValue,
    };
    return config[validatorName];
  }

  public ngOnInit(): void {
    this.control?.valueChanges.pipe(untilDestroyed(this), debounceTime(25), distinctUntilChanged()).subscribe(() => {
      this._setErrorMessage();
    });
  }

  public ngDoCheck(): void {
    if (this.touched !== this.control?.touched) {
      this.touched = this.control?.touched || false;
      this._setErrorMessage();
    } else if (
      this.control?.asyncValidator &&
      this.control?.errors &&
      (this.control?.errors['isTaken'] || this.control?.errors['serialNumberExists'])
    ) {
      this._setErrorMessage();
    } else if (this.control?.errors && this.control?.errors['backend']) {
      this._setErrorMessage();
    } else if (this.control?.errors && this.control?.errors['minlength']) {
      this._setErrorMessage();
    }
  }

  private _setErrorMessage(): void {
    let exists = false;
    for (const propertyName in this.control?.errors) {
      if (Object.prototype.hasOwnProperty.call(this.control?.errors, propertyName)) {
        exists = true;
        this._errorMsg.next(
          ControlMessagesComponent._getValidatorErrorMessage(
            propertyName as errorType,
            this.control?.errors[propertyName],
          ),
        );
      }
    }
    if (!exists) {
      this._errorMsg.next('');
    }
  }
}
