<nb-card [nbSpinner]="(loading$ | async) || false" class="rb-no-margin" nbSpinnerStatus="danger">
  <nb-card-header>
    <span *ngIf="changePasswordRequired">Wymagana zmiana hasła</span>
    <span *ngIf="!changePasswordRequired">Zmiana hasła</span>
    <button
      (click)="onClose()"
      [disabled]="(loading$ | async) || changePasswordRequired"
      aria-label="Close"
      class="close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </nb-card-header>

  <nb-card-body>
    <form
      (ngSubmit)="onSave()"
      [formGroup]="formGroup"
      [ngxsFormClearOnDestroy]="true"
      [ngxsFormDebounce]="100"
      [ngxsForm]="'changePassword.form'"
      novalidate
    >
      <div class="group mt-3">
        <input
          [appInputValidator]="f['currentPassword']"
          [formControlName]="'currentPassword'"
          [fullWidth]="true"
          id="currentPassword"
          nbInput
          type="password"
        />
        <label class="label" for="currentPassword">Stare hasło</label>
        <ams-control-messages [control]="f['currentPassword']"></ams-control-messages>
        <p *ngIf="oldPasswordNotCorrect$ | async" class="error-message">
          {{ oldPasswordNotCorrect$ | async }}
        </p>
      </div>

      <div class="group">
        <input
          [appInputValidator]="f['newPassword']"
          [formControlName]="'newPassword'"
          [fullWidth]="true"
          id="newPassword"
          nbInput
          type="password"
        />
        <label class="label" for="newPassword">Nowe hasło</label>
        <ams-control-messages [control]="f['newPassword']"></ams-control-messages>
        <ams-check-strength [inputStr]="f['newPassword'].value"></ams-check-strength>
      </div>

      <div class="group">
        <input
          [appInputValidator]="f['repeatPassword']"
          [formControlName]="'repeatPassword'"
          [fullWidth]="true"
          id="repeatPassword"
          nbInput
          type="password"
        />
        <label class="label" for="repeatPassword">Powtórzenie nowego hasła</label>
        <ams-control-messages [control]="f['repeatPassword']"></ams-control-messages>
      </div>
    </form>
  </nb-card-body>

  <nb-card-footer class="text-end">
    <button
      (click)="onClose()"
      [disabled]="(loading$ | async) || changePasswordRequired"
      [outline]="true"
      [size]="'small'"
      [status]="'warning'"
      class="me-3"
      nbButton
    >
      <nb-icon [icon]="'arrow-circle-left-outline'"></nb-icon>
      <span>Anuluj</span>
    </button>
    <button
      (click)="onSave()"
      [disabled]="(loading$ | async) || false"
      [outline]="true"
      [size]="'small'"
      [status]="'info'"
      nbButton
    >
      <nb-icon [icon]="'save-outline'"></nb-icon>
      <span>Zapisz</span>
    </button>
  </nb-card-footer>
</nb-card>
