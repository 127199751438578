import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserDropDown } from '@h2h/data';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IPermission } from '../models/permissions/permission';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  constructor(private readonly _httpClient: HttpClient) {}

  /**
   * Get list of user permissions.
   */
  public getAllRoles(): Observable<IPermission[]> {
    return this._httpClient.get<IPermission[]>(`${environment.api}/user/roles`);
  }

  /**
   * Get list of user permissions.
   */
  public getAllPermissions(): Observable<IPermission[]> {
    return this._httpClient.get<IPermission[]>(`${environment.api}/user/permissions`);
  }

  /**
   * Get list of users for dropdown or autocompleter.
   */
  public getUsers(): Observable<IUserDropDown[]> {
    return this._httpClient.get<IUserDropDown[]>(`${environment.api}/user/dropdown`);
  }
}
