import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AppState } from '../app.state';
import { RouteSlug } from '../models/enums/route-slug.enum';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  public loader$ = new Subject<boolean>();
  public loader = false;
  private _jwtHelperService: JwtHelperService;

  constructor(private readonly _store: Store, private readonly _toastrService: ToastrService) {
    this._jwtHelperService = new JwtHelperService();
    this.loader$.pipe(debounceTime(300)).subscribe((loader) => {
      this.loader = loader;
    });
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._canActivate(route);
  }

  public canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._canActivate(route);
  }

  private _canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.loader$.next(true);
    const { token } = this._store.selectSnapshot(AppState);
    // not logged
    if (!token) {
      return this._goToLoginPage();
    }

    const isTokenExpired = this._jwtHelperService.isTokenExpired(token);
    // expired
    if (isTokenExpired) {
      return this._goToLoginPage();
    }

    return this._hasPermission(route);
  }

  private _goToLoginPage(): Observable<boolean> {
    this.loader$.next(false);
    return of(false).pipe(
      tap(() => {
        this._store.dispatch(new Navigate([`/${RouteSlug.AUTH}`, RouteSlug.LOGIN]));
      }),
    );
  }

  private _hasPermission(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permissions = this._store.selectSnapshot(AppState.permissionsObtained);
    const routeRequiredPermissions = route?.data['permissions'];
    let canEnter = !routeRequiredPermissions || routeRequiredPermissions?.length === 0;
    if (!canEnter) {
      for (let i = 0; i < routeRequiredPermissions?.length; i++) {
        const permission = routeRequiredPermissions[i];
        canEnter = permissions.includes(permission);
        if (canEnter) {
          break;
        }
      }
    }
    this.loader$.next(false);
    return of(canEnter).pipe(
      tap((_canEnter) => {
        if (!_canEnter) {
          this._store.dispatch(new Navigate([`/${RouteSlug.FORBIDDEN}`]));
        }
      }),
    );
  }
}
