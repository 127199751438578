import { Routes } from '@angular/router';
import { NbAuthComponent } from '@nebular/auth';
import { RouteSlug } from './models/enums/route-slug.enum';

export const APP_ROUTES: Routes = [
  {
    path: RouteSlug.AUTH,
    component: NbAuthComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/auth/login/login.component').then(({ LoginComponent }) => LoginComponent),
        title: 'Amica SSO Panel - Logowanie',
      },
      {
        path: RouteSlug.LOGIN,
        loadComponent: () => import('./pages/auth/login/login.component').then(({ LoginComponent }) => LoginComponent),
        title: 'Amica SSO Panel - Logowanie',
      },
      {
        path: RouteSlug.FORGOT,
        loadComponent: () =>
          import('./pages/auth/forgotten-password/forgotten-password.component').then(
            ({ ForgottenPasswordComponent }) => ForgottenPasswordComponent,
          ),
        title: 'Amica SSO Panel - Nie pamiętam hasła',
      },
      {
        path: `${RouteSlug.SET_NEW_PASSWORD}/:token`,
        loadComponent: () =>
          import('./pages/auth/change-forgotten-password/change-forgotten-password.component').then(
            ({ ChangeForgottenPasswordComponent }) => ChangeForgottenPasswordComponent,
          ),
        title: 'Amica SSO Panel - Nie pamiętam hasła',
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./pages/main/main.routing').then(({ MAIN_ROUTES }) => MAIN_ROUTES),
  },
];
