export enum PermissionEnum {
  ROLE_CLIENT_MODULE = 'ROLE_CLIENT_MODULE',
  ROLE_PROMOTION_LIST = 'ROLE_PROMOTION_LIST',
  ROLE_PROMOTION_CREATE_EDIT = 'ROLE_PROMOTION_CREATE_EDIT',
  ROLE_APPLICATION_LIST = 'ROLE_APPLICATION_LIST',
  ROLE_APPLICATION_CREATE_EDIT = 'ROLE_APPLICATION_CREATE_EDIT',
  ROLE_COMMUNICATION_MODULE = 'ROLE_COMMUNICATION_MODULE',
  ROLE_POLL_LIST = 'ROLE_POLL_LIST',
  ROLE_POLL_CREATE_EDIT = 'ROLE_POLL_CREATE_EDIT',
  ROLE_ADMINISTRATION_MODULE = 'ROLE_ADMINISTRATION_MODULE',
  ROLE_REPORT_STOKROTKA = 'ROLE_REPORT_STOKROTKA',
  ROLE_REPORT_AWARDS = 'ROLE_REPORT_AWARDS',
  ROLE_REPORT_PRICE_RANGE = 'ROLE_REPORT_PRICE_RANGE',
  ROLE_REPORT_CONCURRENCY = 'ROLE_REPORT_CONCURRENCY',
  ROLE_REPORT_PRODUCT = 'ROLE_REPORT_PRODUCT',
  ROLE_REPORT_PROMOTION = 'ROLE_REPORT_PROMOTION',
  ROLE_REPORT_COMMUNICATION = 'ROLE_REPORT_COMMUNICATION',
  ROLE_REPORT_CATEGORY = 'ROLE_REPORT_CATEGORY',
  ROLE_CALENDAR_ALL_EVENTS = 'ROLE_CALENDAR_ALL_EVENTS',
  ROLE_LIST_SELECTION = 'ROLE_LIST_SELECTION',
  ROLE_REPORT_OPERATOR_WORK = 'ROLE_REPORT_OPERATOR_WORK',
  ROLE_SELLER_PANEL_LIST = 'ROLE_SELLER_PANEL_LIST',
  ROLE_SELLER_PANEL_EDIT = 'ROLE_SELLER_PANEL_EDIT',
  ROLE_REPORT_SELLER = 'ROLE_REPORT_SELLER',
  ROLE_REPORT_AGREEMENT = 'ROLE_REPORT_AGREEMENT',
  ROLE_EDIT_AGREEMENT = 'ROLE_EDIT_AGREEMENT',
  ROLE_CONTACT_CATEGORY_LIST = 'ROLE_CONTACT_CATEGORY_LIST',
  ROLE_CONTACT_CATEGORY_EDIT = 'ROLE_CONTACT_CATEGORY_EDIT',
  ROLE_CONTACT_MODULE = 'ROLE_CONTACT_MODULE',
  ROLE_APPLICATION_DELETE = 'ROLE_APPLICATION_DELETE',
  ROLE_AMICA_SCHOOL = 'ROLE_AMICA_SCHOOL',
  ROLE_CLIENT_MODULE_ANONYMIZE = 'ROLE_CLIENT_MODULE_ANONYMIZE',
  ROLE_CONTACT_DEVELOPER_MODULE = 'ROLE_CONTACT_DEVELOPER_MODULE',
  ROLE_DISCOUNT_CODE = 'ROLE_DISCOUNT_CODE',
  ROLE_SERIAL_NUMBER = 'ROLE_SERIAL_NUMBER',
  // todo remove unused roles
  ROLE_OAUTH_CLIENT_LIST = 'ROLE_OAUTH_CLIENT_LIST',
  ROLE_SMS_LIST = 'ROLE_SMS_LIST',
  ROLE_USER_LIST = 'ROLE_USER_LIST',
  ROLE_EMAIL_LIST = 'ROLE_EMAIL_LIST',
  ROLE_SYSLOG_LIST = 'ROLE_SYSLOG_LIST',
  ROLE_CRONJOB_LIST = 'ROLE_CRONJOB_LIST',
  ROLE_CLIENT_LIST = 'ROLE_CLIENT_LIST',
  ROLE_CLIENT_EDIT = 'ROLE_CLIENT_EDIT',
}
