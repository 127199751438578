import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbSpinnerModule } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { Actions, NgxsModule, ofActionErrored, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { InputValidatorDirective, MatchOtherValidator, PasswordValidator } from '@h2h/data';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { CheckStrengthComponent } from '../check-strength/check-strength.component';
import { ChangePasswordState, ChangePasswordStateChangePassword } from './change-password.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ControlMessagesComponent } from '../../../../components/control-messages.component';
import { AppStateLogout } from '../../../../app.state';

@NgModule({
  imports: [NgxsModule.forFeature([ChangePasswordState])],
})
class ChangePasswordStateLazyModule {}

@Component({
  templateUrl: 'change-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NbCardModule,
    NbInputModule,
    NbSpinnerModule,
    NbIconModule,
    NbButtonModule,
    CommonModule,
    ControlMessagesComponent,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    CheckStrengthComponent,
    InputValidatorDirective,
    ChangePasswordStateLazyModule,
  ],
})
@UntilDestroy()
export class ChangePasswordComponent implements OnInit {
  @Select(ChangePasswordState.loading)
  public loading$?: Observable<boolean>;
  @Select(ChangePasswordState.oldPasswordNotCorrect)
  public oldPasswordNotCorrect$?: Observable<string>;

  public formGroup: UntypedFormGroup;
  @Input()
  public changePasswordRequired = false;

  constructor(
    private readonly _toastrService: ToastrService,
    private readonly _ngbActiveModal: NgbActiveModal,
    private readonly _store: Store,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _actions$: Actions,
  ) {
    this.formGroup = this._buildFormGroup();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  public ngOnInit(): void {
    this._setActionsSubscriptions();
  }

  public onSave(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }
    this._store.dispatch(new ChangePasswordStateChangePassword({ formGroup: this.formGroup }));
  }

  public onClose(): void {
    this._ngbActiveModal.close(false);
  }

  private _buildFormGroup(): UntypedFormGroup {
    return this._formBuilder.group({
      currentPassword: new UntypedFormControl(null, [Validators.required]),
      newPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
        PasswordValidator.number,
        PasswordValidator.upper,
        PasswordValidator.lower,
        PasswordValidator.special,
      ]),
      repeatPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(8),
        MatchOtherValidator.matchOther('newPassword'),
      ]),
    });
  }

  private _setActionsSubscriptions(): void {
    this._actions$.pipe(untilDestroyed(this), ofActionSuccessful(ChangePasswordStateChangePassword)).subscribe(() => {
      this._ngbActiveModal.close();
      this._toastrService.success('Pomyślnie zmieniono hasło.');
      this._store.dispatch(new AppStateLogout());
    });
    this._actions$.pipe(untilDestroyed(this), ofActionErrored(ChangePasswordStateChangePassword)).subscribe(() => {
      this._toastrService.error('Nie udało się zmienić hasła.');
    });
  }
}
